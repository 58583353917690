import '@fontsource-variable/red-hat-mono';
import '@fontsource-variable/red-hat-text';
import { createRoot } from 'react-dom/client';

import { initSentry } from '@/config/sentry';
import App from '@/main/App';
import 'src/config/env';

initSentry();

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);
